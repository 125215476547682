<template>
  <v-col cols="12">
    <v-card flat>
      <v-row
          v-if="hasQrCode"
          class="fill-height"
          align-content="center"
          justify="center"
      >
        <v-col
            class="Login-title text-center"
            cols="12"
        >
          Reconnexion en cours...
        </v-col>
        <v-col cols="6">
          <v-progress-linear
              color="primary accent-4"
              indeterminate
              rounded
              height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>
      <v-row
          v-if="hasTicketCas"
          class="fill-height"
          align-content="center"
          justify="center"
      >
        <v-col
            class="Login-title text-center"
            cols="12"
        >
          Connexion en cours...
        </v-col>
        <v-col cols="6">
          <v-progress-linear
              color="primary accent-4"
              indeterminate
              rounded
              height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>
      <v-form
          v-else-if="!dialogQRCode"
          min-width="300px"
          class="connexionBox d-flex flex-column"
          ref="form"
          v-model="valid"
      >
        <div class="Login-title">
          Accéder à
          <p class="Login-subTitle">mon espace</p>
        </div>
        <v-text-field
            dense
            filled
            v-model="login.login"
            :rules="emailRules"
            autocomplete="off"
            type="text"
            autocorrect="off"
            data-gramm="false"
            spellcheck="false"
            label="identifiant *"
            required
            @keyup.enter="SendLoginReq"
        />
        <v-text-field
            filled
            dense
            :type="showPassword ? 'text' : 'password'"
            v-model="login.password"
            :rules="[() => !!login.password.length ||'Ce champ est requis' ]"
            label="Mot de passe *"
            @keyup.enter="SendLoginReq"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            required
        />
        <div class="d-inline-flex">
          <a
              @click="emitTrigerForgotPass"
              class="Login-link secondary--text"
          >Mot de passe oublié ?</a>
        </div>
        <v-row class="ma-0 pa-0 hidden-xs-only">
          <v-col cols="auto" md="7" lg="5" sm="12" xs="12" class="pa-0">
            <NextButton
                width="100%"
                color="#fb4d61"
                text="me connecter"
                :loading="loading"
                @clicked="SendLoginReq"
            />
          </v-col>
          <v-col cols="auto" md="7" lg="5" sm="12" xs="12" class="pa-0">
            <NextButton
                width="100%"
                class="ml-lg-2 mt-lg-0 mt-md-2 mt-sm-2 mt-xs-2"
                color="primary"
                text="Utiliser un QR code"
                :loading="loading"
                @clicked="dialogQRCode = true"
            />
          </v-col>
        </v-row>
        <NextButton
            width="100%"
            color="#fb4d61"
            class="d-flex d-sm-none"
            text="me connecter"
            :loading="loading"
            @clicked="SendLoginReq"
        />
        <NextButton
            width="100%"
            class="d-flex d-sm-none mt-2"
            color="primary"
            text="Utiliser un QR code"
            :loading="loading"
            @clicked="dialogQRCode = true"
        />
      </v-form>
      <div class="connexionBox d-flex flex-column" v-else-if="dialogQRCode">
        <p v-if="error" class="error pa-2">{{ error }}</p>
        <div class="Login-title">
          Accéder à
          <p class="Login-subTitle">mon espace</p>
        </div>
        <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
            v-if="cameraLoading"
            class="align-center justify-center"
        />
        <qrcode-stream v-if="!error" :camera="camera" :track="paintBoundingBox" @init="onInit" @decode="onDecode">
          <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
              v-if="loading"
              class="align-center justify-center"
          >
          </v-progress-circular>
        </qrcode-stream>
        <v-col cols="12" md="4" lg="4" class="pa-0">
          <NextButton
              width="100%"
              class="mt-3"
              color="primary"
              text="Annuler"
              :loading="loading"
              @clicked="dialogQRCode = false"
          />
        </v-col>
      </div>
    </v-card>
  </v-col>
</template>

<script>
import NextButton from "/src/components/Utility/NextButton";
import AuthenticationService from "/src/services/AuthenticationService";
import {QrcodeStream} from 'vue-qrcode-reader';

export default {
  name: "connexionBox",
  components: {
    NextButton,
    QrcodeStream
  },
  data: () => ({
    valid: false,

    loading: false,
    showPassword: false,
    login: {
      login: "",
      password: "",
    },
    emailRules: [(rule) => !!rule || "Le champ est requis"],
    error: '',
    camera: 'auto',
    dialogQRCode: false,
    cameraLoading: false,
    errorQrCode: false,
    hasTicketCas: false,
  }),

  mounted() {
    if (localStorage.getItem('qrcode')) {
      this.onDecode(localStorage.getItem('qrcode'));
    }

    // On essaye de connecter l'utilisateur si un ticket CAS est présent dans l'URL.
    if (this.$route.query.ticket) {
      this.onDecodeTicketCas(this.$route.query.ticket);
    }
  },

  watch: {
    dialogQRCode(val) {
      if (val) {
        this.camera = 'auto';
      } else {
        this.camera = 'off';
      }
    }
  },
  computed: {
    hasQrCode() {
      if (this.errorQrCode) {
        return false;
      } else {
        return localStorage.getItem('qrcode') !== null;
      }
    }
  },
  methods: {
    emitTrigerForgotPass() {
      this.$emit("forgot-password");
    },
    SendLoginReq() {
      //Valide Formulaire
      this.$refs.form.validate();
      if (this.valid) {
        let id = {
          login: this.login.login.trim(),
          password: this.login.password.trim(),
        };
        this.loading = true;
        AuthenticationService.postLogin(id)
            .then((response) => {
              this.$store
                  .dispatch(
                      "auth/updateAuthorisations",
                      response.data.keycloakIdentificationResponse
                  )
                  .then(() => {
                    this.$store.dispatch("user/updateUser").then(() => {
                      if (this.$store.state.user.role === "GESTIONNAIRE_ARBS" || this.$store.state.user.role === "GESTIONNAIRE_ETABLISSEMENT" || this.$store.state.user.role === "GESTIONNAIRE_ETABLISSEMENT_AVANCE") {
                        this.$router.push({name: "DashboardAdmin"}).catch((error) => {
                          if (error.name !== "NavigationDuplicated") {
                            throw error;
                          }
                        });
                      } else {
                        this.$router.push({name: "Dashboard"}).catch((error) => {
                          if (error.name !== "NavigationDuplicated") {
                            throw error;
                          }
                        });
                      }
                    });
                  });
            })
            .catch((error) => {
              if (error.response.data.details === "Compte désactivé") {
                this.$store.dispatch("setSnackbar", {
                  color: "error",
                  text: "Votre compte est désactivé, contacter l'assistance pour plus de détails.",
                });
              } else {
                this.loading = false;
                this.$emit("error-login");
              }
            });
      }
    },

    onDecodeTicketCas(ticket) {
      this.hasTicketCas = true;
      let data = {
        ticket: ticket.trim(),
      };
      AuthenticationService.postLoginTicketCas(data)
          .then((response) => {
            this.$store
                .dispatch(
                    "auth/updateAuthorisations",
                    response.data.keycloakIdentificationResponse
                )
                .then(() => {
                  this.$store.dispatch("user/updateUser").then(() => {
                    if (this.$store.state.user.role === "GESTIONNAIRE_ARBS" || this.$store.state.user.role === "GESTIONNAIRE_ETABLISSEMENT" || this.$store.state.user.role === "GESTIONNAIRE_ETABLISSEMENT_AVANCE") {
                      this.$router.push({name: "DashboardAdmin"}).catch((error) => {
                        if (error.name !== "NavigationDuplicated") {
                          throw error;
                        }
                      });
                    } else {
                      this.$router.push({name: "Dashboard"}).catch((error) => {
                        if (error.name !== "NavigationDuplicated") {
                          throw error;
                        }
                      });
                    }
                  });
                });
          })
          .catch((error) => {
            if (error.response.data.details === "Compte désactivé") {
              this.$store.dispatch("setSnackbar", {
                color: "error",
                text: "Votre compte est désactivé, contacter l'assistance pour plus de détails.",
              });
            } else {
              this.loading = false
              this.$emit("error-login", error.response.data.details);
            }
            this.hasTicketCas = false;
          }).finally(() => {
        this.loading = false;
      });
    },

    // QRCode Loading
    paintBoundingBox(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const [firstPoint, ...otherPoints] = detectedCode.cornerPoints

        ctx.strokeStyle = "red";

        ctx.beginPath();
        ctx.moveTo(firstPoint.x, firstPoint.y);
        for (const {x, y} of otherPoints) {
          ctx.lineTo(x, y);
        }
        ctx.lineTo(firstPoint.x, firstPoint.y);
        ctx.closePath();
        ctx.stroke();
      }
    },
    async onInit(promise) {
      this.cameraLoading = true;
      try {
        await promise
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.error = "Erreur: vous devez accorder l'autorisation d'accès à la caméra"
        } else if (error.name === 'NotFoundError') {
          this.error = "Erreur: pas de caméra sur cet appareil"
        } else if (error.name === 'NotSupportedError') {
          this.error = "Erreur: contexte sécurisé requis (HTTPS, localhost)"
        } else if (error.name === 'NotReadableError') {
          this.error = "Erreur: l'appareil photo est en cours d'utilisation"
        } else if (error.name === 'OverconstrainedError') {
          this.error = "Erreur: les caméras installées ne conviennent pas"
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = "Erreur: L'API de flux n'est pas prise en charge dans ce navigateur"
        } else if (error.name === 'InsecureContextError') {
          this.error = 'Erreur: L\'accès à la caméra n\'est autorisé que dans un contexte sécurisé. Utilisez HTTPS ou localhost plutôt que HTTP.';
        } else {
          this.error = `Erreur: (${error.name})`;
        }
      } finally {
        this.cameraLoading = false;
      }
    },
    onDecode(qrcode) {
      if (qrcode) {
        this.camera = 'off';
        this.loading = true;
        let data = {
          qrcode: qrcode.trim(),
        };
        AuthenticationService.postLoginQrCode(data)
            .then((response) => {
              this.$store
                  .dispatch(
                      "auth/updateAuthorisations",
                      response.data.keycloakIdentificationResponse
                  )
                  .then(() => {
                    this.$store.dispatch("user/updateUser").then(() => {
                      localStorage.setItem("qrcode", qrcode);
                      if (this.$store.state.user.role === "GESTIONNAIRE_ARBS" || this.$store.state.user.role === "GESTIONNAIRE_ETABLISSEMENT" || this.$store.state.user.role === "GESTIONNAIRE_ETABLISSEMENT_AVANCE") {
                        this.$router.push({name: "DashboardAdmin"}).catch((error) => {
                          if (error.name !== "NavigationDuplicated") {
                            throw error;
                          }
                        });
                      } else {
                        this.$router.push({name: "Dashboard"}).catch((error) => {
                          if (error.name !== "NavigationDuplicated") {
                            throw error;
                          }
                        });
                      }
                    });
                  });
            })
            .catch((error) => {
              if (error.response.data.details === "Compte désactivé") {
                this.$store.dispatch("setSnackbar", {
                  color: "error",
                  text: "Votre compte est désactivé, contacter l'assistance pour plus de détails.",
                });
              } else {
                this.loading = false
                this.$emit("error-login", "QR Code invalide")
              }
              localStorage.removeItem("qrcode");
              this.errorQrCode = true;
              this.camera = 'auto';
            }).finally(() => {
          this.loading = false;
        });
      }
    }
  },
};
</script>

<style scoped>
.connexionBox {
  background-color: white;
  padding: 20px;
}

.Login-subTitle {
  color: #fb4d61;
  font-size: 25px;
  line-height: 18px;
}

.Login-link {
  display: block;
  font-family: "dincondensed", sans-serif;
  color: #fb4d61;
  text-decoration: none;
  margin-bottom: 12px;
}

a {
  position: relative;
  z-index: 50;
}

a:before {
  position: absolute;
  content: "";
  top: -10px;
  right: -10px;
  left: -10px;
  bottom: -10px;
  z-index: 40;
}
</style>
